/* Probing.css */

.probing {
  /* Styles for the header if any */
}

.probing-content {
  font-family: Arial, sans-serif;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #2677862e;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.probing-content h2 {
  margin-top: 20px;
  color: #555;
}

.probing-content button {
  display: block;
  width: 100%;
  padding: 10px 20px;
  margin-top: 10px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.probing-content button:hover {
  background-color: #0056b3;
}

.probing-content ul {
  list-style-type: none;
  padding: 0;
}

.probing-content li {
  margin-bottom: 15px;
}

.probing-content strong {
  font-weight: bold;
  color: #007bff;
}

.probing-content div {
  margin-left: 20px;
}

.probing-content p {
  color: #555;
  margin-top: 10px;
}
