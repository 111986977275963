.container2{
    width:75%;
    margin: 0 auto;
    margin-top: 30px;
}
.details{
    background-color: #ced4da;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    text-align: center;
    border-radius: .375rem;
    margin-bottom: 30px;
    color: white;
}
.ques{
    width: 8%;
    display: flex;
    justify-content: space-between;
}
.ques a{
    text-decoration: none;
}
/* #0a535f */
/* #032c3c */
.data{
    border-radius: 8px;
    background-color: #0a535f ;
    padding: 13px;
    display: flex;
    column-gap: 10px;
    justify-content: center;
    border: 2px solid #ced4da;
    
}
.queContainer{
    display: flex;
    justify-content: space-between;
  }
  .queContainer img{
    width: 50px;
    height: 50px;
    cursor: pointer;
  }




  /* Existing CSS classes */
/* ... */







