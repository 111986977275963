.techbook {
  display: flex;
  padding: 20px;
}

.menu {
  flex: 1;
  margin-right: 20px;
}

.submenu {
  flex: 2;
  margin-right: 20px;
}

.table-container {
  flex: 3;
}

.main-menu {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns */
  gap: 10px;
  list-style-type: none;
  padding: 0;
}

.main-menu li {
  background-color: #0011fcc3;
  color: white;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.main-menu li:hover {
  background-color: #030552e4;
}

.sub-menu {
  list-style-type: none;
  padding: 0;
}

.sub-menu li {
  background-color: #17a2b8;
  color: white;
  padding: 8px;
  cursor: pointer;
  margin: 5px 0;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.sub-menu li:hover {
  background-color: #117a8b;
}

.notes {
  background-color: #f8d7da;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  color: #721c24;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.data-table th, .data-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.data-table th {
  background-color: #f2f2f2;
}
