.admin{
    width:60%;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 30px;
}
.filter{
    width: 80%;
   margin: 0 auto;
}
.bar{
    display: flex;
    justify-content: space-between;
}
.search_icon{
    position: relative;
    left:-70px;
}
.search {
    width: 60%;
    display: flex;
}
.search input{
    width: 100%;
    border-right: none;
    padding: 0px 15px;
    font-size: 18px;
    border: 1px solid;
    border-radius: 2.375rem;
}
.search input:focus {
    outline: none;
    
  }
  .search .search_icon{
    height: 80%;
    position: relative;
    right: 8%;
    border: 1px solid;
    top: 10%;
    /* align-items: center; */
    box-sizing: border-box;
    text-align: center;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    margin: 0px 20px 0px 0px;
    /* padding: 10px; */
}
.search .search_icon i{
    padding: 20px;
}
.searchFilter{
    width: 30%;
    display: flex;
    justify-content: space-around;
}
.searchFilter label{
    width: 70%;
    font-size: 24px;
    text-align: inherit;
}

.list .item{
    background-color: #0a535f;
    display: grid;
    grid-template-columns: 0.5fr 1.5fr 1fr 1.25fr 0.75fr;
    text-align: center;
    border-radius: .375rem;
    margin-bottom: 15px;
    color: white;
}
.case ,.user,.sno,.issue,.view{
    border-right: 1px solid #ced4da;
    border-left: 1px solid #ced4da;
}
.sno{
    border-left: none ;
}
.view{
    border-right:none ;
}
.next{
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}
