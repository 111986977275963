.bubble1 {
    position: absolute;
    height: 80vh;
    width: 80vh;
    top: -40vh;
    right: -40vh;
    background-color: #00596b;
    border-radius: 50%;
    z-index: 2;
  }
  .bubble2 {
    position: absolute;
    height: 300vh;
    width: 300vh;
    top: -200vh;
    right: -150vh;
    background-color: #00778e;
    border-radius: 50%;
    z-index: 1;
  }
  .wrapper {
    height: 100vh;
    width: 100vw;
    padding: 10vh;
    background-color: #007c98;
    overflow: hidden;
    position: relative;
  }
  
  .container {
    min-height: 100%;
    width: 63%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    border-radius: 8px;
    overflow: hidden;
    z-index: 10;
    position: relative;
  }
  .leftWrapper,
  .rightWrapper {
    flex: 1;
    background-color: #ced4da;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .leftWrapper {
    background-image: url("../../Assets/Login1.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .leftContent {
    width: 100%;
    /* border: 1px solid black; */
    color: #fff;
    margin-left: 5%;
    align-items: center;
    text-align: center;
  }
  .leftContent h1 {
    font-size: 6rem;
    text-align: center;
    margin-bottom: 2rem;
    font-weight: 800;
  }
  .leftContent h4 {
    margin: 0;
    width: 50%;
    font-weight: 300;
    text-align: center;
    /* border: 1px solid black; */
  }
  .leftContent div {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-end;
    /* background-color: red; */
  }
  .leftLogo img{
    width: 300px;
    height: 250px;
    position: absolute;
    top: -5%;
    left: 60%;
  }
  .rightContent {
    height: 60%;
    width: 85%;
    padding: 30px;
    padding-top: 50px;
  }
  .rightContent h1 {
    font-weight: 800;
  }
  .form {
    width: 100%;
    margin: 2rem 0rem;
  }
  .input {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
    font-weight: 300;
  }
  .input div {
    width: 100%;
    font-size: 1.4rem;
    padding: 0.2rem;
  }
  .input label {
    font-size: 1.6rem;
    font-weight: 300;
  }
  .continueBtn {
  
    width: 100%;
    margin-top: 4rem;
    font-size: 1.4rem;
    font-weight: 300;
    background: linear-gradient(
      90deg,
      #00596b 13%,
      #007c98  87%
    );
  }
  .continueBtn:hover {
    background: linear-gradient(90deg, #007c98 13%, #00596b 87%);
  }
  .errorMessage{
    color: red;
  }
  .role{
    width:100px;
    height: 50px;
  }
  