.section{
    width: 100%;
    color: white;
  }
  
  .condition{  
   
    border: 1px solid #ced4da;
    border-radius: .375rem;
    padding: 20px;
    background-color: #0a535f;
    
    /* background-image: url('../../../image/1.jpg'); */
}

.condition ul{
    list-style:disc;
}
.condition ul li:first-child{
  margin-top: 20px;
}
.condition ul li{
    width: 100%;
    margin: 10px 15px;
    /* padding: 0px 10px; */
    font-size: 20px;
}


/* list.js */
.probe{
    display: flex;
    width: 98%;
}
.question{
  color: black;
    width:45%;
    height: 25%;
    /* background-color: rgb(0 120 212); */
    border: 1px solid #ced4da;
    border-radius: .375rem;
    padding: 20px;
    background-color: #ced4da;
    /* margin: 0 auto; */
}
.question h3{
  font-size: 24px;
}
.answer{
    /* display: flex; */
    margin-top: 20px;
}
/* .ans{
  width: 15%;
}*/
.ans{
  margin-right: 7px;
}
/* .ans label{
  font-size: 20px;
  line-height: .375rem;
}  */

.rad-label {
  display: flex;
  align-items: center;

  border-radius: 100px;
  padding: 7px 16px;
  margin: 10px 0;

  cursor: pointer;
  transition: .3s;
}

/* .rad-label:hover,
.rad-label:focus-within {
  background: hsl(0, 0%, 100%);
 
} */

.rad-input {
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  height: 1px;
  opacity: 0;
  z-index: -1;
}

.rad-design {
  width: 22px;
  height: 22px;
  border-radius: 100px;

  background: linear-gradient(to right bottom, #1f2325, #1f2325);
  position: relative;
}

.rad-design::before {
  content: '';

  display: inline-block;
  width: inherit;
  height: inherit;
  border-radius: inherit;

  background: hsl(0, 0%, 90%);
  transform: scale(1.1);
  transition: .3s;
}

.rad-input:checked+.rad-design::before {
  transform: scale(0);
}

.rad-text {
  color: black;
  margin-left: 14px;
  /* letter-spacing: 1px; */
  /* text-transform: uppercase; */
  font-size: 18px;
  font-weight: 600;

  transition: .3s;
}

.button button{
  float: right;
  margin-top: 20px;
  background-color: #1f2325;
  color: white;
  width: 17%;
  border: none;
  font-size: 20px;
  font-weight: 500;
  border-radius: .285rem;
  transition: .3s;
}

.button1 button{
  margin-top: 20px;
  background-color: #1f2325;
  color: white;
  width: 17%;
  border: none;
  font-size: 20px;
  font-weight: 500;
  border-radius: .285rem;
  transition: .3s;
}

/* left list */
.boxContainer{
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
}

.description{
  width: 50%;
  color: white;
}
.description h2{
  font-size: 26px;
}
.description h3{
  margin-top: 10px;
  font-size: 20px;
  font-family: 400;
}
.ul ul li{
  margin-bottom: 0;
  list-style: decimal;
  font-weight: 400;
}
.solution{
  margin: 20px 0px;
  
  background-color:#0a535f;
  color: white;
  border: 1px solid #ced4da;
    border-radius: .375rem;
    padding: 20px;
}
.solution h3{
  font-size: 20px;
}
.solution h2{
  font-size: 26px;
}
