/* Article.css */

.main_container2 {
  padding: 20px;
  background-color: #3c848ee8; /* Light background */
}

.heading_column {
  font-size: 35px;
  margin-bottom: 10px;
  color: #333;
}

.troubleshooting_block {
  display: grid; /* Use grid for three columns */
  grid-template-columns: repeat(3, 1fr); /* Three equal columns */
  gap: 20px; /* Space between columns */
  margin-bottom: 30px; /* Space below this block */
}

.info_block {
  display: flex; /* Use flexbox for two columns */
  gap: 30px; /* Space between the columns */
}

.info_column {
  flex: 1; /* Equal space for both columns */
  background: #ffffff; /* White background for columns */
  padding: 15px;
  border: 1px solid #ddd; /* Light border */
  border-radius: 8px; /* Rounded corners */
}

.dropdown {
  border: 1px solid #ddd; /* Border for dropdown */
  border-radius: 5px; /* Rounded corners */
  margin-bottom: 10px; /* Space between dropdowns */
}

.dropdown_title {
  cursor: pointer; /* Pointer cursor for clickable title */
  padding: 10px; /* Padding for the title */
  background-color: #e9ecef; /* Light background for title */
  border-radius: 5px; /* Rounded corners */
}

.dropdown_content {
  padding: 10px; /* Padding for dropdown content */
  background-color: #f1f1f1; /* Slightly darker background for dropdown content */
  border-radius: 5px; /* Rounded corners */
}

.link_css {
  display: block; /* Block display for links */
  margin: 5px 0; /* Margin for spacing */
  color: #007bff; /* Link color */
  text-decoration: none; /* No underline */
}

.link_css:hover {
  text-decoration: underline; /* Underline on hover */
}
