.itemContainer{
    width:50%;
    margin: 0 auto;
}

.itemDetails{
    display: grid;
    grid-template-columns: 0.75fr 2fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    margin-top: 30px;
    text-align: center;
    width: 80%;
    border: 1px solid;
    border-right: none;
    background-color: aliceblue;
}

.gridTitle,.gridValue{
    border-right: 1px solid;
    padding: 10px 0px;
    border-bottom: 1px solid #b3b3b3;
    
}
.gridTitle{

    text-align: center;
}
.gridTitle p,.gridValue p{
    margin: 0;
    padding: 8px 0px;
    font-size: 24px;
    font-weight: 500;
}
.gridValue p{
    font-weight: 400;
}
.gridTitle:first-child,.gridValue:first-child{
    padding-top: 10px;
}
.gridTitle:last-child{
    padding-bottom: 10px;
    border-bottom:none ;
}
.gridValue:last-child{
    padding-bottom: 10px;
    border-bottom:none ;
}

.troubleshootingSteps{
    margin-top: 30px;
}

.itemList{
    display: grid;
    grid-template-columns: 0.5fr 1.75fr 1fr;
    text-align: center;
    border: 1px solid;
    background-color: aliceblue;
    margin-top: 20px;
}
.itemList .grid_item{
    padding: 10px 0px;
    border-right: 1px solid;
}
.itemList .grid_item:last-child{
    border-right: none;
}
.itemList .grid_item p{
    margin: 0;
    font-size: 24px;
    font-weight: 500;
}

.itemValue{
    display: grid;
    grid-template-columns: 0.5fr 1.75fr 1fr;
    text-align: center;
}
.itemValue .grid_value{
    padding: 10px 0px;
}

.hr{
    margin: 0;
}
.itemValue .grid_value p{
    margin: 0;
    font-size: 20px;
    font-weight: 400;
}

.ref{
    margin-top: 30px;
}

.sol{
    margin-top: 20px;
    background-color: aliceblue;
    border: 1px solid;
    margin-bottom: 30px;
}
.sol p{
    margin: 0;
    font-size: 22px;
    font-weight: 400;
    padding: 10px;
}

.copy {
    display: flex;
    justify-content: space-between;
}

.copy img{
    width: 50px;
    height: 50px;
    cursor: pointer;
}