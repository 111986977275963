.header {
  background-color: #1f2325;
  color: white;
  height: 80px;
  border-bottom: 4px solid black;
}

.container {
  width: 80%;
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title1 {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.logo img {
  width: 100px;
  height: 80px;
}

.title {
  height: 100%;
  padding: 20px 10px;
}

.list {
  color: rgb(166, 165, 165);
}

.list ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  height: 100%;
  flex-wrap: nowrap; /* Prevent wrapping by default */
}

.list ul li {
  margin: 0 15px;
  font-size: 18px;
}

.list ul li:hover {
  color: #f98416;
  cursor: pointer;
}

.title a,
.ready_reckoner a,
.probing a {
  text-decoration: none;
  color: white;
}

.list ul li a {
  text-decoration: none;
  color: rgb(166, 165, 165);
}

.list ul li a:hover {
  color: #f98416;
}

/* SuperAdmin-specific styling */
.superadmin-menu ul {
  flex-wrap: nowrap; /* Prevent menu from wrapping */
  gap: 15px; /* Space out items */
}

.superadmin-menu ul li {
  font-size: 17px; /* Adjust font size if needed */
  margin: 10px 10px; /* Adjust spacing to fit more items */
}

.superadmin-menu .dashboard-link {
  color: #f98416; /* Highlight the SuperAdmin Dashboard link */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: center;
  }

  .list ul {
    justify-content: center;
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
  }
}
