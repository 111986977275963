.feedback-form {
  max-width: 600px;
  margin: 20px auto;
  padding: 30px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #8ad8e0cd;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.feedback-form:hover {
  transform: translateY(-5px);
}

.feedback-form h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
  font-size: 24px;
  font-weight: 600;
}

.feedback-form label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
}

.feedback-form input,
.feedback-form textarea,
.feedback-form select {
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.feedback-form input:focus,
.feedback-form textarea:focus,
.feedback-form select:focus {
  border-color: #28a745; /* Highlight border color on focus */
  outline: none; /* Remove default outline */
}

.feedback-form button {
  display: block;
  width: 100%;
  padding: 12px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.feedback-form button:hover {
  background-color: #218838;
  transform: translateY(-2px);
}

.feedback-form button:active {
  transform: translateY(0);
}

.feedback-form .checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.feedback-form .checkbox-container input {
  margin-right: 10px;
}
