.list_item{
    display: grid;
    grid-template-columns:0.51fr 1.5fr 1fr 1.25fr 0.75fr;
    text-align: center;
    color: black;
}
.case_item p,.user_item p,.sno_item p,.issue_item p,.view_item a{
    font-size: 22px;
    font-weight: 400;
}

.under{
    margin-top: 0px;
}

